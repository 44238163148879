#map{
  @include breakpoint(medium down){
    .site-header{
      display: none;
      height: 0;
    }
    .site-footer{
      display: none;
      height: 0;
    }
  };
}

#Googlemap{
  width: 100%;
  height: calc(100vh - #{$site-header-height-pc} - #{$page-title-height-pc} + 1px);
  @include breakpoint(medium down){
    height: calc(100vh - 300px - #{$site-header-height-pc});
  };
}


.map-header{
  background-color: $pine-green-two;
  height: 50px;
  line-height: 50px;
}
#MapHeader .row{ max-width: none; }

.mapframe{
  position: relative;
  .button-wrap{
    position: absolute;
    @include breakpoint(large up){
      bottom: 1.25rem;
      right: 1.5rem;
    }
    @include breakpoint(medium down){
      bottom: 2rem;
      width: 100%;
      top: initial;
      left: 0;
      text-align: center;
      bottom: 1rem;
    };
    .button{
      padding: .75rem 2.8rem;
      background-color: $medium-green;
      border: solid 1px $fresh-green;
    }
  }
}

.map-icon{
  line-height: 36px;
  display: inline-block;
  border-radius: 5px;
  i{
    transition: .75s;
  }
  &:hover{
    i{
      @extend %bright-link-hover;
    }
  }
}
