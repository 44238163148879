.small-banner{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  max-width:375px;
  width: 100%;
  height: $site-header-height-pc;
  padding: 0;
  background-color: $white;
  color: $medium-gray;
  @include breakpoint(medium down){
    position: fixed;
    max-width: none;
    top: auto;
    bottom: 0;
  };
  .tip{
    @extend %pr;
    position: absolute;
    top: 25px;
    left: -18px;
    @include breakpoint(medium down){
      top: -0.5rem;
      left: 90%;
      z-index: 200;
    };
  }
  img{
    display: block;
    max-width:375px;
    width: 100%;
    margin: auto;
  }
}

#result{
  .site-footer{
    @include breakpoint(medium down){
      display: none;
      height: 0;
    };
  }
}
