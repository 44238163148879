
.result-header__class{
  @extend %Notosans;
}

.result-header__name{
  @extend %Notosans;
}

.result-header{
  padding-top: 33px;
  padding-left: 0.5rem;
  @include breakpoint(medium down){
    padding: 7px .5rem;
    padding-bottom: .5rem;
    .row{
      flex-wrap: nowrap;
    }
  };
  &__class{
  font-size: 28px;
  font-weight: 900;
  font-style: normal;
  color: $white;
  display: flex;
  position: relative;
  padding-right: 1.25rem;
  @include breakpoint(medium down){
    display: none;
  };
  &:after{
    content: '';
    width:1px;
    height: 30px;
    position: absolute;
    display: inline-block;
    top: 5px;
    right: .25rem;
    background-color: $white;
    }
  }
  &__name{
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: $white;
    margin-bottom: 0;
    padding-right: 1rem;
    @include breakpoint(medium down){
      padding-left: .25rem;
      padding-right: .25rem;
      padding-top: .15rem;
    };
  }
  &__count{
    text-align: right;
    color: $tan;
    font-family: $Notosans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    @include breakpoint(large up){
      width: fit-content;
      max-width: fit-content;
    };
  }
  &__icon,
  &__number{
    position: relative;
    overflow: hidden;
    height: 40px;
    font-family: $Roboto;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    letter-spacing: 1;
    color: $pine-green;
    background-color: white;
    border-radius: 5px;
    padding: .25rem .5rem;;
    display: inline-block;
    margin-left: .25rem;
    line-height: 28px;
    top: 4px;
  }
  &__number{
    padding: .25rem .5rem;
  }
  &__icon{
    padding: .5rem .5rem;
  }
}

.g-search-undefind{
  height: 100%;
  .row{
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.undefind-info{
  font-size: 12px;
  border-top: solid 1px $tan;
  border-bottom: solid 1px $tan;
  color: $tan;
  width: 100%;
  line-height: calc(#{$timetable-tr-height});
  padding-top: calc(#{$timetable-tr-height} * 1.5);
  padding-bottom: calc(#{$timetable-tr-height} * 1.5);
  text-align: center;
  height: fit-content;
  margin-bottom: 1.5rem;
  @extend %Notosans;
}

.scroll_btn{
  cursor: pointer;
  position: absolute;
  padding: .35rem 1.25rem;
  z-index: 20;
  font-weight: bold;
  color: $fern-green;
  &.active{
    color: $fresh-green;
  }
  &.yesterday{ left: 0; }
  &.tommorow{ right: 0; }
}

.g-search-card{
  &{
    position: relative;
    padding-left: 9px;
    padding-right: 20px;
    max-width: 388px;
    @include breakpoint(medium down){
      padding: 0 .65rem 75px;
      max-width: none;
    };
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 10%;
      right: 0;
      height: 80%;
      width: 1px;
      background-color: $pine-green;
    }
    main{
      height: 100%;
    }
  }
  &__row{
    width: 100vw;
    height: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0rem;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style:none;
    @include breakpoint(medium down){
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    };
  }
  &__wrap{
    @include breakpoint(medium down){
      height: auto;
      padding-top: 0.25rem;
      position: relative;
    };
  }
  &__header{
    text-align: center;
    padding-bottom: 0;
  }
  &__scroller {
    overflow-y: auto;
    height: calc(100% - 80px);
    @include breakpoint(medium down){ height: calc(100% - 75px); }
  }
  &__title{
    position: relative;
    display: inline-block;
    color: $tan;
    font-family: $Roboto;
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1;
    margin: 0 auto;
    // reset input
    appearance: none;
    background: none;
    border:none;
    text-align: center;
    &:focus{
      background: $pine-green;
    }
    @include breakpoint(medium down){
      display: block;
      width: calc(100vw - 5em);
      font-family: $Roboto;
      font-size: 20px;
      line-height: 2.25rem;
      padding: 0;
      margin-top: 0rem;
    };
    // スピンボタン（上下ボタン）を非表示
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    // /の色
    // &::-webkit-datetime-edit-text { color: ; }
    // clearボタンを非表示
    &::-webkit-clear-button {
      // position: absolute;
      // right: 0.5em;
      -webkit-appearance: none;
    }
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0.5em;
    }
  }
  &__daylink{
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    color: $fresh-green;
    border: solid 1px $fresh-green;
    border-radius: 5px;
    display: inline-block;
    padding:3px 12px;
    margin-bottom: 1em;
    position: relative;
    top: -2px;
    transition: .75s;
    @extend %bright-link-hover;
    @include breakpoint(medium down){
      margin-top: 0;
    };
  }
  main{
    @include breakpoint(medium down){
      height: calc(100vh - 80px - 75px - 40px - 85px + 2rem);
      overflow: scroll;
    };
  }
  &__timetable{
    border-top: solid 1px $fresh-green;
    width: 100%;
    margin-top: -1px;
    padding-bottom: 2rem;
    tr{
      max-height: $timetable-tr-height;
      &.row{
        margin: 0rem;
        border-bottom: solid 1px $fresh-green;
        padding-top: 23px;
      }
      th{
        font-size: 10px;
        color: white;
        @extend %Roboto;
      }
      td{
        position: relative;
        display: flex;
        flex:100%;
      }
    }
  }
}

.reserv{
  position: absolute;
  top: 100%;
  width: (87.5% / 2);
  left: 12%;
  background-color: $white;
  font-size: 12px;
  display: flex;
  border-radius: 5px;
  height: calc(#{$timetable-tr-height} * 2);
  min-height: 15px;
  &:only-of-type{
    width: 87.5%;
  }
  &:last-of-type{
    left: inherit;
    right: 0;
  }
  &__inner{
    width: 100%;
    position: relative;
    align-items: center;
    display: flex;
    .tip{
      position: absolute;
      padding: 0px 15px;
      top: -.5rem;
      left: -.5rem;
      white-space: nowrap;
      @extend %pr;
    }
  }
  &.half{
    top: 200%;
  }
  span,a{
    line-height: 1.25;
    font-weight: 800;
    font-family: $Notosans;
    color: $medium-gray;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
  }
  .anti{
    display: block;
    font-weight: 800;
    color: $fern-green;
  }
  &.thin .anti{
    display: inline-block;
  }
}

.t-search-lists{
  &__wrap{
    margin: 1em auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: $white;
    & > p {
      padding: 0 0.5rem 0 3.25rem;
      font-size: rem-calc(14);
      @include breakpoint(large up){
        padding-left: 14.5rem;
      };
    }
  }
}
.t-search-list{
  &{
    padding-top: 0.5rem;
    border-top: solid 1px $fresh-green;
  }
  .map-icon{
    margin-right: 0.5em;
  }
  li.column{
    margin: 0;
    padding-bottom: 0;
    a{
      display: block;
      padding-top: 1em;
      padding-bottom: 1em;
    }
    &:last-child{
      border-bottom: solid 1px $fresh-green;
    }
  }
}
.t-search-table{
  width: 100%;
  color: $white;
  margin-top: 0.25rem;
  border-top: solid 1px $fresh-green;
  border-collapse: collapse;
  @include breakpoint(large up){
    padding-bottom: 1.5rem;
    margin-top: 1rem;
  };
  .t-search-list &{
    margin: 0.5em 0 -1px;
    border-top-style: dashed;
  }
  &__wrap{
    // -ms-overflow-style:none;
    // overflow: scroll;
    padding-left: 11.25rem;
    @include breakpoint(medium down){
      // height: calc(100% - 55px);
      padding-left: 0;
    };
    &.loading{
      font-size: rem-calc(12);
      font-weight: bold;
    }
  }
  &__game{
    margin-bottom: 0.5em;
    padding-bottom: 0.75em;
    border-bottom: solid 1px $fresh-green;
  }
  dl{
    margin: 0 0 0.125em;
  }
  &__hotel{
    margin: 0;
    padding-right: .75rem;
    font-weight: bold;
  }
  &__link{
    position: relative;
    padding-left: 0.75em;
    padding-right: .5rem;
    font-weight: bold;
    color: $tan;
    &:before{
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      height: 1.5em;
      width: 1px;
      background-color: white;
    }
    a{
      color: $fresh-green;
      display: inline-block;
    }
  }
  &__time{
    @extend %Notosans;
    time{
      @extend %Roboto;
      font-size: 18px;
      display: inline-block;
      @include breakpoint(medium down){ padding-bottom: 0.25em; }
    };
    .versus{
      color: $tan;
    }
    .challenger{
      color: $fresh-green;
    }
    @include breakpoint(medium down){
      width: 100%;
      flex: 0 0 auto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
    };
  }
  &__name{
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    @include breakpoint(medium down){
      font-size: 12px;
    };
  }
  th{
    vertical-align: middle;
    color: $tan;
    font-size: 20px;
    padding: .25rem .5rem;
    padding-bottom: .65rem;
    @extend %Roboto;
  }
  td{
    padding: .5rem;
  }
  td,th{
    border-bottom: solid 1px $fresh-green;
    // position: relative;
    // &:after{
    //   content: '';
    //   display: block;
    //   background-color: $fresh-green;
    //   height: 1px;
    //   width: calc(100% + .5rem);
    //   position: absolute;
    //   bottom: 0;
    //   left: -.25rem;
    // }
  }
}
