/* = = = form = = = */

label{
  position: relative;
  .tip{
    position: absolute;
    @extend %error;
  }
}

select{
  width: 310px;
  margin-bottom: .25rem;
  border-radius: 5px;
  font-size: 12px;
}

textarea,
input{
  &.exportTextarea,
  &[type='text'],&[type='email'],&[type='tel'],&[type='submit']{
    border-radius: 5px;
  }
  &[type='text']{
    width: 310px;
    margin-bottom: .25rem;
  }
  &[type='submit']{
    padding: 10px 30px;
    font-size: 14px;
    font-family: $Notosans;
    background-color: $medium-green;
    border-color: $fresh-green;
    color: $white;
    font-weight: bold;
    border-style: solid;
    transition: .75s;
    @extend %green-button-hover;
  }
}

.search-form{
  text-align: center;
  &__wrap{
    padding: 0 1.2rem;
  }
  @include breakpoint(medium down){
    margin: 0 auto;
      &__wrap{
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0 0rem;
      }
      &__button{
        margin: 0 auto;
        font-family: $Notosans;
    }
  };
  &__title{
    font-family: $Notosans;
    font-size: 16px;
    font-weight: bold;
    color: $white;
    margin-bottom: 1rem;
  }
}


.search-form__button{
  @extend %green-button-hover;
}

/* = = =  END Form  = = = */
