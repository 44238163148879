$Roboto:'Roboto Condensed', sans-serif;
$Notosans:'Noto Sans Japanese',sans-serif;


$white:#f7f8f7;
$medium-gray:#707770;

$blue-green: #0e9f8d;
$pine-green: #114a11;
$pine-green-two: #103e10;
$medium-green: #37ac3a;
$fresh-green: #6ed571;
$fern-green: #3c763d;

$tan:#cec266;
$sand-brown: #ce8266;
$sans: sans-serif;





$site-header-height-pc: 75px;
$site-header-height-sp: 80px;

$page-title-height-pc: 50px;

$site-footer-height-pc: 80px;

$large-banner-height-pc: 250px;
$large-banner-height-sp: 250px;
$small-banner-height: 75px;

$timetable-tr-height:39px;


/* --- extends --- */

%pr{
  background-color: $blue-green;
  color: white;
}
%error{
  background-color: $sand-brown;
  color: white;
  top: 1.5rem;
  left: -0.25rem;
  padding: 0 1.1em;
}

/* =  =  =   links & buttons   =  =  = */

%green-button-hover{
  transition: .75s;
  &:hover{
    background-color: $pine-green;
    border-color: $medium-green;
    transition: .75s;
  }
}

%bright-link-hover{
  &:hover{
    transition: .5s;
    border-color: $white;
    color: $white;
    background-color: $blue-green;
  }
}

.button{
  padding: 10px 30px;
  font-size: 14px;
  background-color: $medium-green;
  border-color: $fresh-green;
  color: $white;
  font-weight: bold;
  border-style: solid;
  @extend %green-button-hover;
}


/* = = = fonts = = = */

%Roboto{
  font-family: $Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
};
%Notosans{
  font-family: $Notosans;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
};

/* === icons === */

.try-arrow{
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    &.tobottom{
      transform: rotate(0deg);
    }
    &.toleft{
      transform: rotate(90deg);
    }
  &:before{
    border-top: 14px solid #6ed571;
    border-right: 9px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 7px solid transparent;
    content: '';
    position: absolute;
    display: inline-block;
    bottom: -10px;
    left: 4px;
    height: 0px;
  }
}

/* common CSS set */


.medium-down-expand{
  @include breakpoint(medium down){
    width: 100%;
  };
}

.devlink{
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 30;
  @include breakpoint(medium down){
    bottom: 0;
    left: 0;
  };
  a{
    font-size: 12px;
    display: inline-block;
    background-color: $tan;
    color: $white;
    font-weight: bold;
    padding: 2.5px 5px;
    border-radius: 5px;
  }
}

.tip{
  font-family: $Roboto;
  color:$fern-green;
  background-color: #103e10;
  text-decoration: none;
  border-radius: .75em;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 10px;
  font-style: normal;
}


/* = = = selecter-CSS = = = */

html{
  overflow-x: hidden;
}

body{
    background-color: #0c580f;
    position: relative;
    overflow: scroll;
}

a{
  color: $white;
  transition: .5s;
  &:hover{
    color: $fresh-green;
    transition: .5s;
  }
}

main{
  &.home{
    position: relative;
    .select-search{
      position: relative;
    }
    .select-search:after{
      content: '';
      position: absolute;
      left: calc(50% - 1px);
      top: -50%;
      bottom: -50%;
      z-index: -1;
      display: block;
      background-color: #114a11;
      width: 1px;
      @include breakpoint(medium down){
        height: 1px;
        width: 90vw;
        top: calc(50%);
        left: 5%;
      };
    }
  }
}


.wraptext{
  font-family: $Roboto;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.3;
  letter-spacing: 1;
  color: $fresh-green;
  border: solid 1px $fresh-green;
  border-radius: 5px;
  padding: .25rem .5rem;;
  display: inline-block;
}
