.main-content.entry{
  &{
    display: flex;
    align-items: center;
    padding: 1em 2.5%;
    overflow-y: auto;
  }
  .require{
    font-weight: bold;
    border-color: $tan;
    font-size: 1rem;
    color: $tan;
  }
  label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $white;
    @include breakpoint(small down){
      margin: 1em 0 0.25em;
    }
  }
  @include breakpoint(small down){
    th, td{
      display: block;
      padding: 0;
    }
    th{
      border: none;
      text-align: left;
    }
  };
  h2{
    font-weight: bold;
    font-size: 1.25rem;
    color: $fresh-green;
  }
  table{
    margin-bottom: 1em;
  }
  th{
    padding-top: 0;
    padding-bottom: 0;
  }
  td{
    position: relative;
  }
  input[type="text"]{
    width: 100%;
    margin-bottom: 0;
  }
}

.autosuggest{
  &__results-container{
    position: relative;
  }
  &__results{
    overflow-y: auto;
    position: absolute; z-index: 256;

    background-color: $white;
    border-bottom: 1px solid $fresh-green;
    border-radius: 4px;
    width: 100%;
    max-height: 50vh;
    ul{
      @extend .no-bullet;
      margin: 0;
    }
  }
  &__results-item{
    padding: 0.5em 1em;
    background-color: $white;
    border-bottom: 1px solid $medium-gray;
    color: $pine-green;
    &:hover{
      background-color: darken($white, 5%);
    }
  }
}

.add-table{
  &__date{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include breakpoint(small down){
      margin-bottom: 0.5em;
    }
    .date{
      width: 10em;
      margin-bottom: 0;
      margin-right: 1em;
      border-radius: 5px;
      @include breakpoint(small down){
        width: 100%;
        margin-bottom: 0.5em;
        margin-right: 0;
      }
    }
    .hour,.min{
      width: 4em;
      margin-bottom: 0;
    }
  }
  &__separate{
    margin-left: 0.25em;
    margin-right: 0.5em;
  }
}

.add-button{
  @extend .g-search-card__daylink;
  margin: 0 1em !important;
}

.g-recaptcha{
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
