body.unload{
  visibility: hidden;
}
iframe{
  width: 100%; height: calc(100vh - 75px - 50px);
  border:none;
}
i[class^="ss-"]{
  vertical-align: middle;
}
.site-header{
  color: $white;
  background-color: $pine-green;
  &__title-wrap{
    display: flex;
    height: $site-header-height-pc;
    position: relative;
    overflow: hidden;
    .try-arrow{
      display: inline-block;
      margin-left: 0.75rem;
    }
    a{
      &.row{
        margin: 0 !important;
      }
    }
    @include breakpoint(medium down){
      width: 100%;
      height: 80px;
      a{
        width: 100%;
        .column{
          padding: 0 .5rem;
          width: auto;
          h1{
            padding-left: 0;
          }
          &.shrink{
            position: relative;
            font-size: 28px;
            font-weight: bold;
            span{
              display: inline-block;
              margin-left: 0.333rem;
            }
            &:after{
              position: absolute;
              content: "";
              height: calc(100% - 10px);
              top: 5px;
              right: 0;
              width: 1px;
              background-color: white;
              display: block;
            }
          }
        }
      }
    };
    &:after{
      content: '';
      display: block;
      background-color: $pine-green-two;
      height: 1rem;
      width: calc(100% + 2rem);
      position: absolute;
      bottom: -.35rem;
      left: 0;
      transform: rotate(-.5deg);
    }
  }
  &__title,
  &__title-eng{
    padding-left: 0.5rem;
  }
  &__title{
    font-size: 18px;
    font-family: $Notosans;
    margin:0;
    font-weight: 800;
    line-height: .9;
    span{
      font-weight: 400;
    }
    @include breakpoint(medium down){
      font-size: 12px;
      line-height: 1.2;
    };
  }
  &__title-eng{
    font-size:11px;
    font-weight: bold;
    font-family: $Roboto;
    font-stretch: condensed;
    letter-spacing: 1.5px;
  }
  &__banner{
    width:375px;
    height: 75px;
    color: $medium-gray;
    background-color: $white;
    position: relative;
    @include breakpoint(medium down){
      position: absolute;
      z-index: 2;
      bottom: 0;
    };
    .tip{
      @extend %pr;
      position: absolute;
      top: calc(50% - .5rem);
      left: -1rem;
      @include breakpoint(medium down){
        top: -0.5rem;
        left: 90%;
        z-index: 200;
      };
    }
  }
}

.site-footer{
  height: $site-footer-height-pc;
  background-color: $pine-green-two;
  padding: 1rem;
  padding-top: 2rem;
  color: $white;
  @include breakpoint(medium down){
    bottom: 0;
    width: 100%;
    margin-top: 0;
    padding: 1.4rem 0rem;
    height: auto;
    background-color: inherit;
    text-align: center;
  };
  &__inner{
    height: 100%;
  }
  &__powered{
    font-family: $Roboto;
    font-size: 11px;
    font-weight: bold;
    padding-left: 1.25rem;
    position: absolute;
    bottom: 10px;
    right: 8px;
    @include breakpoint(medium down){
      position: static;
      padding-right: 1.25rem;
    };
  }
  &__navs{
    font-size: 14px;
    text-align: right;
    color: $white;
    a{
      margin-bottom: .5rem;
      font-weight: bold;
    }
  }
  &__links{
    padding-left: .75rem;
    // width: 150px;//折り返しが発生して崩れる箇所がある
    ul{
      margin:0;
      font-size:10px;
      line-height:15px;
      list-style:none;
    }
  }
}

.main-content{
  min-height:calc(100vh - #{$site-footer-height-pc} - #{$site-header-height-pc});
  color: $white;
  @include breakpoint(medium down){
    min-height: fit-content;
    &.lonsoff{
      height: calc(100vh - #{$large-banner-height-sp} - #{$site-header-height-sp});
    }
    &.lonson{
      height: calc(100vh - #{$large-banner-height-sp} - #{$small-banner-height});
    }
    &.loffson{
      height: calc(100vh - #{$small-banner-height} -  #{$site-header-height-sp});
    }
  };
  &.map{
    @include breakpoint(large up){
      height:calc(100vh - #{$site-header-height-pc} + .25rem);
    };
  }
  &.lonsoff{
    @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .column {
      min-height:calc(100vh - #{$site-footer-height-pc} - #{$site-header-height-pc});
      align-items: center;
      justify-content: center;
      display: flex;
     } /* IE11 */
    }
    .column{
      // padding: 0 3rem;
      @include breakpoint(medium down){
        padding: 0;
      };
    }
    .tip{
      padding: 0 10px;
      display: inline-block;
    }
  }
}

#result{
  .site-footer{
    display: block;
    @include breakpoint(medium down){
      display: none;
    };
  }
}

//loading

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

%loop-rotate {
  animation: spin 1.5s linear infinite;
  transform-origin:center;
}

.loading{
  &__content{
    color: $white;
    text-align: center;
  }
  &__icon{
    display: block;
    // width: 2em; height: 2em;
    font-size: 4em;
    &.spin:before{
      @extend %loop-rotate;
      display: inline-block;
      padding-top: 0.25em;
    }
  }
  &__text{
    font-weight: bold;
  }
}
