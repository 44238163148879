main {
    display : block;
}
figure {
    margin : 0;
}
.util-youtube {
    display : block;
    overflow : hidden;
    position : relative;
    padding-top : 56.25%;
    height : 0;
    iframe {
        position : absolute;
        top : 0;
        left : 0;
        width : 100%;
        height : 100%;
    }
}

.navigation-modal {
    display : none;
    position : fixed;
    z-index : 1;
    top : 0;
    bottom : 0;
    left : 0;
    right : 0;
    width : 100vw;
    height : 100vh;
}

address {
    margin-bottom : 1em;
    font-style : normal;
    font-size : rem-calc(12);
}

.row {
    &::before , ::after {
        display : none;
    }
}
.column {
    width : inherit;
}
